.profile__name {
  max-width: 400px;
  margin: 27px 0 0 0;
  padding: 0;
  font: 500 42px/1.14 'Inter', sans-serif;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 709px) {
  .profile__name {
    max-width: 300px;
    font-size: 27px;
    line-height: 1.21;
  }
}

@media (max-width: 499px) {
  .profile__name {
    max-width: 250px;
  }
}

@media (max-width: 399px) {
  .profile__name {
    max-width: 200px;
  }
}