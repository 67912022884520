.popup__heading {
  margin: 34px auto 0 36px;
  padding: 0;
  font: 900 24px/1.21 'Inter', sans-serif;
  color: #000;
}

@media (max-width: 709px) {
  .popup__heading {
    margin: 25px auto 0 22px;
    font-size: 18px;
  }
}