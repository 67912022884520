.profile__info {
  position: relative;
  margin: 0 0 0 30px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

@media (max-width: 709px) {
  .profile__info {
    margin-left: 0;
    align-items: center;
  }
}