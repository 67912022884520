.header__button_type_menu {
  width: 24px;
  height: 24px;
  display: none;
  padding-right: 27px;
  transition: opacity .3s ease-in;
}

.header__button_type_menu div {
  width: 100%;
  height: 3px;
  background-color: #fff;
  margin: 3px 0 3px 0;
}

.header__button_type_menu:hover {
  cursor: pointer;
  opacity: .6;
}

@media (max-width: 560px) {
  .header__button_type_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}