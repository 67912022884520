.popup__button-submit_type_noactive {
  box-sizing: border-box;
  color: #000000;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #000000;
  opacity: .3;
  cursor: default;
}

.popup__button-submit_type_noactive:hover {
  opacity: .3;
}