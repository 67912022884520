.popup__close-icon {
  width: 32px;
  height: 32px;
  background: url(../../../images/Close-Icon.svg) no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

.popup__close-icon:hover {
  opacity: 0.6;
}

@media (max-width:709px) {
  .popup__close-icon {
    width: 20px;
    height: 20px;
    top: -36px;
    right: 0;
  }
}