.header__link {
  margin: 0 0 0 24px;
  padding: 0;
  font: 400 18px/1.2 'Inter', sans-serif;
  text-decoration: none;
  color: #FFFFFF;
  align-self: center;
  transition: opacity .3s ease-in;
}

.header__link:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media (max-width: 909px) {
  .header__link {
    padding-right: 27px;
  }
}

@media (max-width: 560px) {
  .header__link {
    font-size: 14px;
  }
}