.entry__input {
  width: 100%;
  min-height: 27px;
  margin-top: 50px;
  margin-bottom: 2px;
  padding: 0 0 9px 0;
  box-sizing: border-box;
  font-style: inherit;
  display: block;
  color: #fff;
  border: 0;
  border-radius: 2px;
  border-bottom: 2px solid #cccccc;
  background-color: transparent;
  font: 400 14px/1.21 'Inter', sans-serif;
}

.entry__input + .entry__input {
  margin-top: 30px;
  margin-bottom: 216px;
}

@media (max-width: 560px) {
  .entry__input {
    margin-top: 40px;
  }

  .entry__input + .entry__input {
    margin-bottom: 173px;
  }
}