.logo {
  width: 142px;
  height: 33px;
  object-fit: cover;
}

@media (max-width: 909px) {
  .logo {
    margin-left: 27px;
  }
}

@media (max-width: 499px) {
  .logo {
    width: 103.74px;
    height: 24.4px;
  }
}