.entry__container {
  width: 358px;
  margin: 0 auto;
  padding-top: 60px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

@media (max-width: 560px) {
  .entry__container {
    width: 260px;
    padding-top: 40px;
  }
}