.entry__heading {
  margin: 0;
  padding: 0;
  font: 900 24px/1.2 'Inter', sans-serif;
  text-align: center;
  color: #fff;
}

@media (max-width: 560px) {
  .entry__heading {
    font-size: 20px;
  }
}