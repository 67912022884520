.element__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  background-color: #000;
  color: #fff;
}