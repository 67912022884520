.header {
  max-width: 880px;
  min-height: 119px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header::after {
  content: '';
  border-bottom: 1px solid rgba(84, 84, 84, .7);
}

@media (max-width: 879px) {
  .header {
    max-width: 100%;
  }
}

@media (max-width: 560px) {
  .header {
    min-height: 84px;
  }
}