.entry__button-submit {
  width: 100%;
  height: 50px;
  padding: 0;
  margin: 46px 0 0 0;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  font-style: inherit;
  font: 400 18px/1.22 'Inter', sans-serif;
  text-align: center;
  transition: background-color .3s ease-in;
  margin-top: auto;
}


.entry__button-submit:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
}

.entry__button-submit:focus {
  outline: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
}

@media (max-width: 560px) {
  .entry__button-submit {
    height: 46px;
    font-size: 16px;
  }
}