.element__like {
  width: 22px;
  height: 19px;
  background-image: url(../../../images/heart.svg);
  opacity: 1;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

.element__like:hover {
  opacity: 0.5;
  cursor: pointer;
}