.popup__input {
  width: 358px;
  height: 27px;
  margin: 48px 0 0 0;
  padding: 0 0 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.2);
  font: 400 14px/1.21 'Inter', sans-serif;
  color: #000;
}

.popup__input-error-place + .popup__input {
  margin-top: 0;
}

@media (max-width: 709px) {
  .popup__input {
    width: 238px;
    margin-top: 75px;
  }
}