.popup__container {
  position: relative;
  min-width: 430px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 709px) {
  .popup__container {
    min-width: 282px;
  }
}