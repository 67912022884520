.popup__input-error {
  display: block;
  box-sizing: content-box;
  min-height: 30px;
  max-width: 358px;
  font: 400 12px/1.2 'Inter', sans-serif;
  color: #FF0000;
  padding: 5px 0 5px 0;
  margin: 0;
  z-index: 5;
  align-self: flex-start;
}

@media (max-width: 709px) {
  .popup__input-error {
    max-width: 238px;
  }
}