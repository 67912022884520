.popup__heading_type_tooltip {
  max-width: 358px;
  margin: 0;
  text-align: center;
}

@media (max-width: 709px) {
  .popup__heading_type_tooltip {
    max-width: 244px;
    font: 900 20px/1.2 'Inter', sans-serif;
  }
}