.footer__copyright {
  max-width: 318px;
  margin: 0;
  padding: 0;
  font: 400 18px/1.21 'Inter', sans-serif;
  color: #545454;
}

@media (max-width: 499px) {
  .footer__copyright {
    font-size: 14px;
  }
}