.profile__change-button {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  background-image: url(../../../images/change-avatar.svg);
  background-position: center;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  display: none;
  z-index: 10;
}