.profile__calling {
  max-width: 400px;
  margin: 8px 0 0 0;
  padding: 0;
  font: 400 18px/1.2 'Inter', sans-serif;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 709px) {
  .profile__calling {
    max-width: 300px;
    font-size: 14px;
  }
}

@media (max-width: 499px) {
  .profile__calling {
    max-width: 250px;
  }
}

@media (max-width: 399px) {
  .profile__calling {
    max-width: 200px;
  }
}