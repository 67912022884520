.profile {
  max-width: 880px;
  padding-top: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 909px) {
  .profile {
    max-width: calc(100% - 2*30px);
  }
}

@media (max-width: 849px) {
  .profile {
    max-width: calc(100% - 2*19px);
    padding-top: 42px;
    flex-direction: column;
    justify-content: center;
  }
}